<template>
  <div class="p-3" tabindex="1" role="dialog" aria-labelledby="updateAppModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title pb-3" id="updateAppModalLabel">Ауыл Таксидің жаңа қосымшасын орнатыңыз</h5>
        </div>
        <div class="modal-body">
          Кәзіргі қосымша қолданыстан шықты, орнына <span style="color: red">NomadGo</span> қосымшасын орнатыңыз
        </div>
        <div class="modal-footer">
          <button @click="updateAppBtn" type="button" class="btn btn-success mt-3 w-100">Жаңарту</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  methods: {
    updateAppBtn() {
      window.open('https://onelink.to/w6348a', '_blank');
    },
  }
}
</script>

<style>
@import'~bootstrap/dist/css/bootstrap.css';
</style>
